<template>
    <section class="section">
        <ValidationObserver v-slot="{ handleSubmit }">
            <v-form class="mt-15" @submit.prevent="handleSubmit(onSubmit)">
                <v-sheet id="scrolling-techniques-7" class="overflow-y-auto">
                    <v-container>
                        <v-tabs center-active>
                            <v-tab>{{ $t('ParkProfile') }}</v-tab>
                            <v-tab-item>
                                <v-row class="mt-10">
                                    <v-col
                                        class="form-pic justify-center d-flex d-xs-flex d-sm-flex d-md-none"
                                        cols="12"
                                        md="3">
                                        <v-avatar
                                            :color="form.pictures ? '' : 'primary'"
                                            :class="form.pictures ? '' : 'v-avatar-light-bg primary--text'"
                                            size="200">
                                            <v-img v-if="getPic" :src="getPic"></v-img>
                                            <span v-else class="display-1 font-weight-medium">{{
                                                avatarText(form.name)
                                            }}</span>
                                            <v-file-input
                                                name="photo"
                                                v-show="!isDisabled"
                                                @change="onFileInput"
                                                v-model="file"
                                                class="file"
                                                hide-input></v-file-input>
                                        </v-avatar>
                                    </v-col>
                                    <v-col cols="12" md="9">
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <ValidationProvider
                                                    :name="$t('Form.Name')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                                    <v-text-field
                                                        :error="errors[0] ? true : false"
                                                        v-model="form.name"
                                                        :disabled="isDisabled"
                                                        :label="$t('Form.Name')"
                                                        required
                                                        filled
                                                        hide-details="auto"
                                                        name="name"
                                                        shaped></v-text-field>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <ValidationProvider
                                                    :name="$t('Form.Phone')"
                                                    rules="required|mobile-phone"
                                                    v-slot="{ errors }">
                                                    <v-text-field
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        filled
                                                        shaped
                                                        v-model="form.phone"
                                                        :label="$t('Form.Phone')"
                                                        name="tel"
                                                        hide-details="auto"
                                                        required></v-text-field>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col cols="12" md="6">
                                                <ValidationProvider
                                                    :name="$t('Form.Email')"
                                                    rules="required|email"
                                                    v-slot="{ errors }">
                                                    <v-text-field
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        filled
                                                        shaped
                                                        v-model="form.email"
                                                        :label="$t('Form.Email')"
                                                        name="email"
                                                        hide-details="auto"
                                                        required></v-text-field>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <div class="d-flex align-center">
                                                    <v-select
                                                        :disabled="isDisabled"
                                                        :items="['09:00', '24:00']"
                                                        filled
                                                        shaped
                                                        :label="$t('Form.Schedule')"></v-select>
                                                    <p class="px-4">às</p>
                                                    <v-select
                                                        :disabled="isDisabled"
                                                        :items="['09:00', '24:00']"
                                                        filled
                                                        shaped
                                                        :label="$t('Form.Schedule')"></v-select></div
                                            ></v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col
                                        class="form-pic d-none d-md-flex d-lg-flex d-xl-flex justify-end"
                                        cols="12"
                                        md="3">
                                        <v-avatar
                                            :color="form.pictures ? '' : 'primary'"
                                            :class="form.pictures ? '' : 'v-avatar-light-bg primary--text'"
                                            size="150">
                                            <v-img v-if="getPic" :src="getPic"></v-img>
                                            <span v-else class="display-1 font-weight-medium">{{
                                                avatarText(form.name)
                                            }}</span>
                                            <v-file-input
                                                name="photo"
                                                v-show="!isDisabled"
                                                @change="onFileInput"
                                                v-model="file"
                                                class="file"
                                                hide-input></v-file-input>
                                        </v-avatar>
                                    </v-col>

                                    <v-col cols="12" md="9">
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <ValidationProvider
                                                    :name="$t('Form.Cost')"
                                                    rules="required|min_value:0"
                                                    v-slot="{ errors }">
                                                    <v-text-field
                                                        :min="0"
                                                        :error="errors[0] ? true : false"
                                                        hide-details="auto"
                                                        :disabled="isDisabled"
                                                        filled
                                                        shaped
                                                        type="number"
                                                        v-model="form.price"
                                                        :label="$t('Form.Cost')"
                                                        suffix="€/hora"
                                                        required></v-text-field>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col cols="12" md="6">
                                                <v-select :disabled="isDisabled" filled label="Sensores"></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                    <v-col cols="12" md="4">
                                        <ValidationProvider
                                            :name="$t('Form.PostalCode')"
                                            rules="required|postal-code"
                                            v-slot="{ errors }">
                                            <v-text-field
                                                @change="getAddresses()"
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                v-model="form.zipCode"
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                :label="$t('Form.PostalCode')"
                                                required></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" md="8">
                                        <ValidationProvider
                                            :name="$t('Form.Address')"
                                            rules="required"
                                            v-slot="{ errors }">
                                            <v-combobox
                                                :items="addresses"
                                                item-value="Artéria"
                                                item-text="Artéria"
                                                v-model="form.address"
                                                hide-details
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                hide-no-data
                                                :label="$t('Form.Address')"
                                                required></v-combobox>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="3">
                                        <ValidationProvider :name="$t('Country')" rules="required" v-slot="{ errors }">
                                            <v-autocomplete
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                @change="getDistricts"
                                                v-model="selectedCountry"
                                                item-value="id"
                                                item-text="name"
                                                :items="countries"
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                name="country"
                                                :label="$t('Country')"></v-autocomplete>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="3">
                                        <ValidationProvider :name="$t('District')" rules="required" v-slot="{ errors }">
                                            <v-autocomplete
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                @change="getCounties"
                                                :items="districts"
                                                item-text="name"
                                                item-value="id"
                                                v-model="selectedDistrict"
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                :label="$t('District')"></v-autocomplete>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="3">
                                        <ValidationProvider :name="$t('County')" rules="required" v-slot="{ errors }">
                                            <v-autocomplete
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                @change="getParishes"
                                                :items="counties"
                                                item-text="name"
                                                item-value="id"
                                                v-model="selectedCounty"
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                :label="$t('County')"></v-autocomplete>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="3">
                                        <ValidationProvider :name="$t('Parish')" rules="required" v-slot="{ errors }">
                                            <v-autocomplete
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                :items="parishes"
                                                item-text="name"
                                                item-value="id"
                                                v-model="selectedParish"
                                                @change="getParishCoords"
                                                :disabled="isDisabled"
                                                filled
                                                :label="$t('Parish')"></v-autocomplete>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <ValidationProvider
                                            :name="$t('Form.LatLng')"
                                            rules="required|lat-lgn"
                                            v-slot="{ errors }">
                                            <v-text-field
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                :disabled="isDisabled"
                                                v-model="coordinatesField"
                                                filled
                                                shaped
                                                :label="$t('Form.LatLng')"></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="3">
                                        <ValidationProvider :name="$t('ParkType')" rules="required" v-slot="{ errors }">
                                            <v-autocomplete
                                                :error="errors[0] ? true : false"
                                                hide-details="auto"
                                                :items="['indoor', 'outdoor']"
                                                v-model="selectedParkType"
                                                :disabled="isDisabled"
                                                filled
                                                :label="$t('ParkType')"></v-autocomplete>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="3">
                                        <ValidationProvider :name="$t('ParkType')" rules="required" v-slot="{ errors }">
                                            <v-checkbox
                                                v-model="form.isActive"
                                                :label="$t('ActivePark')"
                                                :value="true"
                                                hide-details
                                                :disabled="isDisabled"></v-checkbox>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="" cols="12" sm="12">
                                        <ValidationProvider :name="$t('Form.Keywords')" v-slot="{ errors }">
                                            <v-text-field
                                                :error="errors[0] ? true : false"
                                                v-model="form.keywords"
                                                :disabled="isDisabled"
                                                :label="$t('Form.Keywords')"
                                                filled
                                                hide-details="auto"
                                                name="name"
                                                shaped></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <GmapMap
                                            :center="place.coordinates"
                                            :zoom="16"
                                            map-type-id="terrain"
                                            style="width: 100%; height: 500px">
                                            <GmapMarker
                                                :position="place.coordinates"
                                                :clickable="true"
                                                :draggable="false" />
                                        </GmapMap>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                        </v-tabs>
                    </v-container>
                </v-sheet>
                <v-app-bar height="80" absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7">
                    <v-btn @click="$router.go(-1)" class="square-button mx-3" color="grey-light">
                        <v-icon large color="grey">
                            {{ mdiChevronLeft }}
                        </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="!addPark">
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('ParkProfile') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('ParkProfile') }}
                        </p>
                    </v-toolbar-title>
                    <v-toolbar-title v-else>
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('AddPark') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('AddPark') }}
                        </p>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <div v-if="!addPark">
                        <v-btn type="submit" v-show="!isDisabled" :loading="isLoading" class="mx-3" color="primary">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                                {{ mdiCheck }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="ehite">
                                {{ mdiCheck }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Save') }}</span>
                        </v-btn>
                        <v-btn v-show="!isDisabled" @click="isDisabled = true" outlined class="mx-3" color="error">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Cancel') }}</span>
                        </v-btn>

                        <v-dialog transition="dialog-top-transition" max-width="600">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-show="isDisabled" class="mx-3" color="grey-light" v-bind="attrs" v-on="on">
                                    <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Delete') }}</span>
                                </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                                <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/delete.png"></v-img>
                                <v-card>
                                    <v-card-text class="text-center">
                                        <p class="title font-weight-semibold black--text mt-12">
                                            {{ $t('Alerts.Sure') }}
                                        </p>
                                    </v-card-text>
                                    <v-card-text class="text-center">
                                        <p class="body-2">
                                            {{ $t('Alerts.SureDelete') }}
                                            <strong class="black--text">{{ form.name }}</strong> ?<br />
                                            {{ $t('Alerts.Irreversible') }}
                                        </p>
                                    </v-card-text>

                                    <v-card-actions class="justify-end mt-4">
                                        <v-btn @click="deletePark" color="error" class="px-5">{{
                                            $t('Buttons.Delete')
                                        }}</v-btn>
                                        <v-btn text @click="dialog.value = false">{{ $t('Buttons.Cancel') }}</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </div>
                    <v-btn v-else type="submit" :loading="isLoading" class="mx-3" color="primary">
                        <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Conclude') }}</span>
                    </v-btn>
                </v-app-bar>
            </v-form>
        </ValidationObserver>
        <!-- ALERTAS DE SUCESSO E ERRO A ELIMINAR PARQUES-->
        <v-snackbar :timeout="2000" top v-model="createSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.AddSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="updateSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.UpdateSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isDeleteSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.DeleteSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isError" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/failed.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Error') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.ErrorMessage') }}</p>
        </v-snackbar>
    </section>
</template>
<script>
    import {
        mdiChevronLeft,
        mdiFileDocumentEditOutline,
        mdiInformationOutline,
        mdiPlus,
        mdiTrashCanOutline,
        mdiCheck,
        mdiClose,
        mdiChevronRight,
        mdiMagnify,
        mdiHelp,
    } from '@mdi/js';
    import useParkList from '../park-list/useParkList';
    import useUsersList from '../../user/user-list/useUsersList';
    import { avatarText } from '@core/utils/filter';
    import { uploadFile } from '@/api/upload';
    import { getLocations } from '@/api/location';
    import _ from 'lodash';
    import ParkStructure from '@/views/parks/park-structure/ParkStructure.vue';
    import { getUsers } from '@/api/user';
    import { addSections, updateSections, deleteSections } from '../../../api/sections.js';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { getAddress } from '@/api/address';
    import { addLots, updateLots } from '@/api/lots';

    const initialState = () => ({
        isLoading: false,
        form: {
            name: '',
            price: 0,
            address: '',
            zipCode: '',
            pictures: [],
            managers: [],
            sections: [],
            phone: '',
            entity: null,
            schedules: [],
            sensorType: 'individual',
            observations: '',
            longitude: 0,
            latitude: 0,
            freeLots: 0,
            totalCapacity: 0,
            pregnantCapacity: 0,
            handicappedCapacity: 0,
            chargerCapacity: 0,
            monitoringType: null,
            isActive: true,
        },

        isLoadingTable: true,
        structure: [],
    });

    export default {
        components: {
            ParkStructure,
            ValidationProvider,
            ValidationObserver,
            Map,
        },

        data() {
            return {
                ...initialState(),
                isDisabled: true,
                valid: false,
                nameRules: [
                    (v) => !!v || 'Name is required',
                    // (v) => v.length <= 10 || 'Name must be less than 10 characters',
                ],
                //   costRules: [(v) => !!v || 'Cost is required', (v) => (v && v <= 0) || 'Não pode ser menor que 0'],
                emailRules: [(v) => !!v || 'E-mail is required', (v) => /.+@.+/.test(v) || 'E-mail must be valid'],

                file: null,
                countries: [],
                districts: [],
                counties: [],
                parishes: [],
                selectedUsers: [],
                managers: [],
                selectedCountry: null,
                selectedDistrict: null,
                selectedCounty: null,
                selectedParish: null,
                selectedParishLat: null,
                selectedParishLng: null,
                addPark: false,
                dialog: false,
                place: {
                    coordinates: {
                        lat: 0,
                        lng: 0,
                    },
                },
                sectionsToDelete: [],
                createSuccess: false,
                updateSuccess: false,
                isDeleteSuccess: false,
                isError: false,
                coordinatesField: null,
                addresses: [],
                searchUser: '',
                selectedParkType: null,
                selectedStructure: null,
                generalLots: {},
            };
        },
        async created() {
            if (this.$route.params.id != 'add') {
                this.addPark = false;
                this.isDisabled = true;
                await this.getPark();
            } else {
                this.addPark = true;
                this.isDisabled = false;
            }
            await this.getUsersName();
            await this.getCountries();
        },

        setup() {
            const { resolveParkPictureMax, limitParkText } = useParkList();
            const { resolveUserPicture, tableColumns, totalUserListTable } = useUsersList();

            return {
                mdiInformationOutline,
                mdiFileDocumentEditOutline,
                mdiCheck,
                mdiClose,
                mdiTrashCanOutline,
                mdiPlus,
                mdiChevronLeft,
                mdiChevronRight,
                mdiMagnify,
                mdiHelp,
                resolveParkPictureMax,
                avatarText,
                resolveUserPicture,
                tableColumns,
                totalUserListTable,
                limitParkText,
            };
        },
        computed: {
            getPic() {
                return this.resolveParkPictureMax(this.form.pictures);
            },
        },
        methods: {
            editInformation() {
                this.isDisabled = !this.isDisabled;
            },
            //get park info
            getPark() {
                this.isLoading = true;
                this.$store
                    .dispatch('app-park/fetchPark', this.$route.params.id)
                    .then((response) => {
                        this.form = response.data.data;
                        this.selectedCountry = _.get(this.form, 'location.parent.parent.parent.id');
                        this.selectedDistrict = _.get(this.form, 'location.parent.parent.id');
                        this.selectedCounty = _.get(this.form, 'location.parent.id');
                        this.selectedParish = _.get(this.form, 'location.id');

                        let managers = _.get(this.form, 'managers');

                        this.selectedUsers = managers ? managers : [];
                        this.getDistricts();
                        this.getCounties();
                        this.getParishes();
                        this.getAddresses();
                        this.coordinatesField = this.form.latitude + ', ' + this.form.longitude;
                        this.place.coordinates.lat = this.form.latitude;
                        this.place.coordinates.lng = this.form.longitude;
                        this.selectedParkType = this.form.type;
                        this.generalLots = {
                            normalCapacity: this.form.normalCapacity,
                            pregnantCapacity: this.form.pregnantCapacity,
                            handicappedCapacity: this.form.handicappedCapacity,
                        };
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            this.form = {};
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            async getCountries() {
                await getLocations({ filters: { level: 0 } }).then((response) => {
                    this.countries = response.data.data;
                });
            },
            getDistricts() {
                this.districts = [];
                this.counties = [];
                this.parishes = [];
                getLocations({ filters: { parent: this.selectedCountry } }).then((response) => {
                    this.districts = response.data.data;
                });
            },
            getCounties() {
                this.counties = [];
                this.parishes = [];
                getLocations({ filters: { parent: this.selectedDistrict } }).then((response) => {
                    this.counties = response.data.data;
                });
            },
            getParishes() {
                getLocations({ filters: { parent: this.selectedCounty } }).then((response) => {
                    this.parishes = response.data.data;
                });
            },
            async getAddresses() {
                this.addresses = [];
                await getAddress(this.form.zipCode).then((response) => {
                    this.addresses = response.data.partes;
                });
            },

            getParishCoords() {
                this.selectedParishLat = this.parishes.find((parish) => {
                    return parish.id === this.selectedParish;
                }).latitude;
                this.selectedParishLng = this.parishes.find((parish) => {
                    return parish.id === this.selectedParish;
                }).longitude;
                this.place.coordinates.lat = this.selectedParishLat;
                this.place.coordinates.lng = this.selectedParishLng;
            },
            async getUsersName(search) {
                await getUsers({
                    populate: 'pictures, role, parkingLots, picture',
                    sort: ['name:asc'],
                    'filters[$or]': [
                        {
                            name: {
                                $containsi: search,
                            },
                        },
                        {
                            email: {
                                $containsi: search,
                            },
                        },
                        {
                            parkingLots: {
                                name: {
                                    $containsi: search,
                                },
                            },
                        },
                    ],
                })
                    .then((response) => {
                        this.managers = response.data.data;
                    })
                    .finally(() => {
                        this.isLoadingTable = false;
                    });
            },

            deletePark() {
                this.$store
                    .dispatch('app-park/deletePark', this.$route.params.id)
                    .then((response) => {
                        this.isDeleteSuccess = true;
                        setTimeout(() => {
                            this.$router.push({ name: 'ParkList' });
                        }, 1500);
                    })
                    .catch((error) => {
                        this.isError = true;
                    });
            },

            searchUsers() {
                this.isLoadingTable = true;

                this.getUsersName(this.searchUser);
                setTimeout(() => {
                    this.isLoadingTable = false;
                }, 1000);
            },

            getFinalStructure(structure, type, selectedStructure) {
                this.structure = structure;
                this.monitoringType = type;
                this.selectedStructure = selectedStructure;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .is-icon {
        background-color: #0000ff;
        border-radius: 10px;
    }

    .v-avatar {
        position: relative;
        .file {
            background-color: #0000ff;
            border-radius: 10px;
            padding: 0.3rem;
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translate(0%, 0%);
        }
    }
    .v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
        box-shadow: 0 0px 0px 0px rgb(94 86 105 / 42%) !important;
    }
</style>
